.container {
  min-height: 100vh;
  background-color: var(--main-bg-color);
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--main-white);
  margin-left: 1em;
  margin-right: 1em;
}

@supports (scroll-snap-align: start) {
  .container {
    scroll-snap-align: start;
  }
}

@supports not (scroll-snap-align: start) {
  /* old scroll snap points spec */
  .container {
    scroll-snap-coordinate: 0 0;
  }
}

.content {
  justify-content: center;
  padding-left: 1em;
}

h2 {
  font-family: 'Roboto Mono', monospace;
  font-size: 2em;
}

.text {
  font-size: 1.5em;
  font-family: 'Roboto Mono', monospace;
}

@media only screen and (min-width: 768px) {
  .container {
    margin-left: 0;
    margin-right: 0;
  }

  .content {
    justify-content: center;
    padding-left: 3em;
  }

  .text {
    width: 50%;
  }
}
