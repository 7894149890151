@import url('https://fonts.googleapis.com/css?family=Roboto|Roboto+Mono&display=swap');

body {
  scroll-behavior: smooth;
  padding: 0;
  font-family:
    "Roboto",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--main-bg-color);
  display: flex;
  margin-top: 0;
  margin-left: 0;
  margin-right: 0;

  /* margin-right: 1em;
  margin-bottom: 0; */

  /*  Variables */
  --main-bg-color: #0a192f;
  --main-color: #3498db;
  --dark-color: #2c3e50;
  --main-white: #e6f0ff;
  --color-green: #2ed199;
  --color-blue: #6487a5;
}

@media only screen and (min-width: 768px) {
  h1 {
    font-size: 3em;
  }

  h3 {
    font-size: 1.17em;
  }

  body {
    /* margin-top: 1em;
    margin-left: 3em;
    margin-right: 3em;
    margin-bottom: 0; */
  }
}

#root {
  width: 100%;
}

a {
  color: #3498db;
}

a:hover {
  text-decoration: none;
}
