.text_box {
  width: 100%;
}

.text_box a {
  color: var(--color-green);
}

/*
@media only screen and (min-width: 768px) {
  .text_box {
    width: 50%;
  }
} */
