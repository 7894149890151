@import url(https://fonts.googleapis.com/css?family=Roboto|Roboto+Mono&display=swap);
body {
  scroll-behavior: smooth;
  padding: 0;
  font-family:
    "Roboto",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--main-bg-color);
  display: flex;
  margin-top: 0;
  margin-left: 0;
  margin-right: 0;

  /* margin-right: 1em;
  margin-bottom: 0; */

  /*  Variables */
  --main-bg-color: #0a192f;
  --main-color: #3498db;
  --dark-color: #2c3e50;
  --main-white: #e6f0ff;
  --color-green: #2ed199;
  --color-blue: #6487a5;
}

@media only screen and (min-width: 768px) {
  h1 {
    font-size: 3em;
  }

  h3 {
    font-size: 1.17em;
  }

  body {
    /* margin-top: 1em;
    margin-left: 3em;
    margin-right: 3em;
    margin-bottom: 0; */
  }
}

#root {
  width: 100%;
}

a {
  color: #3498db;
}

a:hover {
  text-decoration: none;
}

.App {
  margin-left: -11px;
  margin-bottom: -11px;
  height: 100vh;
  overflow-y: scroll;
  scroll-behavior: smooth;

  /* scroll-snap-type: y mandatory; */
  -webkit-overflow-scrolling: touch;
}

@supports (scroll-snap-align: start) {
  /* modern scroll snap points */
  .App {
    scroll-snap-type: y mandatory;
  }
}

@supports not (scroll-snap-align: start) {
  /* old scroll snap points spec */
  .App {
    scroll-snap-type: mandatory;
    scroll-snap-destination: 100% 0;
    scroll-snap-points-y: repeat(100%);
  }

  .scroll-item-outer {
    scroll-snap-coordinate: 0 0;
  }
}

::-webkit-scrollbar {
  width: 0;  /* remove scrollbar space
  background: transparent;  /* optional: just make scrollbar invisible */
}

/* optional: show position indicator in red */
::-webkit-scrollbar-thumb {
  background: #ecf0ff;
}

.Button_normal__1ZMze {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 4px solid var(--dark-color);
  color: var(--dark-color);
  cursor: pointer;
  font-size: 1.7em;
  transition: 0.5s ease;

  /* padding: 0.25em 0.5em 0.25em 0.5em; */
}

.Button_normal__1ZMze:hover {
  background-color: var(--dark-color);
  border-color: var(--dark-color);
  color: var(--main-bg-color);
  transition: 0.5s ease;
}

.Header_Header__2C_Ub {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  max-width: 95vw;
}

.Header_right__XlGVu {
  display: flex;
}

.Header_hideSmall__37Lqh {
  display: none;
}

.BurgerIcon_container__1-llB {
  display: inline-block;
  cursor: pointer;
  z-index: 10;
}

.BurgerIcon_bar1__a6biW,
.BurgerIcon_bar2__2igb4,
.BurgerIcon_bar3__3uSNI {
  width: 35px;
  height: 5px;
  background-color: #333;
  margin: 6px 0;
  transition: 0.4s;
}

/* Rotate first bar */
.BurgerIcon_change__3Hzo2 .BurgerIcon_bar1__a6biW {
  -webkit-transform: rotate(-45deg) translate(-9px, 6px);
  transform: rotate(-45deg) translate(-9px, 6px);
}

/* Fade out the second bar */
.BurgerIcon_change__3Hzo2 .BurgerIcon_bar2__2igb4 {
  opacity: 0;
}

/* Rotate last bar */
.BurgerIcon_change__3Hzo2 .BurgerIcon_bar3__3uSNI {
  -webkit-transform: rotate(45deg) translate(-8px, -8px);
  transform: rotate(45deg) translate(-8px, -8px);
}

.Menu_container__3WQ8u {
  z-index: 10;
  background-color: var(--color-green);
  height: 100vh;

  /* width: 100vw; */
}

.Menu_menuList__3rs_T li a {
  color: inherit;
  text-decoration: none;
}

.Menu_menuList__3rs_T {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-family: 'Roboto Mono', monospace;
  list-style: none;
  counter-reset: li;
  padding: 15px

  /* list-style: none; */
}

.Menu_menuList__3rs_T li::before {
  content: '.0'counter(li);
  color: var(--main-color);
  display: inline-block;
  width: 1em;
  margin-left: -1.5em;
  margin-right: 0.5em;
  text-align: right;
  direction: rtl;
}

.Menu_menuList__3rs_T li {
  transition: 0.5s;
  color: var(--main-white);
  counter-increment: li;
}

.Menu_menuList__3rs_T li:hover {
  transition: 0.5s;
  color: var(--main-color);
  cursor: pointer;

  /* margin-right: 12px; */
}

.Menu_hideSmall__2Mw8W {
  display: none;
}

.Menu_showFull__2EdMF {
  height: 100vh;
  width: 100vw;
  background-color: var(--color-green);
}

@media only screen and (min-width: 768px) {
  .Menu_hideSmall__2Mw8W {
    display: flex;
  }

  .Menu_hideLarge__TkUiI {
    display: none;
  }

  .Menu_container__3WQ8u {
    z-index: 0;

    /* background-color: green; */
    height: 100%;

    /* width: 100%; */
  }

  .Menu_menuList__3rs_T {
    width: 30vw;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}

.MobileMenu_container__21jvq {
  z-index: 99;
  width: 75vw;
  height: 100vh;
  position: fixed;
  left: -5vw;
  padding-left: 15vw;
  background-color: var(--main-bg-color);
}

.MobileMenu_menuList__SN7kf {
  font-size: 25pt;
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-family: 'Roboto Mono', monospace;
  list-style: none;
  counter-reset: li;
}

.MobileMenu_menuList__SN7kf li a {
  color: inherit;
  text-decoration: none;
}


.MobileMenu_menuList__SN7kf li::before {
  content: '.0'counter(li);
  color: var(--main-color);
  display: inline-block;
  width: 1em;
  margin-left: -1.5em;
  margin-right: 0.5em;
  text-align: right;
  direction: rtl;
}

.MobileMenu_menuList__SN7kf li {
  transition: 0.5s;
  color: var(--main-white);
  counter-increment: li;
}


.MobileMenu_hideMenu__2sRjp {
  display: none;
}

@media only screen and (min-width: 768px) {
  .MobileMenu_hideSmall__3pzff {
    display: none;
  }
}

.TextBox_text_box__OXPyH {
  width: 100%;
}

.TextBox_text_box__OXPyH a {
  color: var(--color-green);
}

/*
@media only screen and (min-width: 768px) {
  .text_box {
    width: 50%;
  }
} */

.JobContainer_container__ML_iQ {
  overflow-y: scroll;
  height: 75vh;
}

.JobContainer_loadingContainer__IZYtc {
  width: 100vw;
  height: 75vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.JobBlock_container__1nYQG {
  width: 95vw;
}

.JobBlock_container__1nYQG h3 {
  font-family: 'Roboto Mono', monospace;
  color: var(--color-green);
  margin-bottom: 5px;
}

.JobBlock_container__1nYQG h4 {
  color: var(--main-white);
  opacity: 0.6;
  margin-bottom: 0;
  margin-top: 0;
}

.JobBlock_container__1nYQG p {
  font-family: 'Roboto Mono', monospace;
  color: var(--main-white);
  margin-top: 0;
  overflow-x: hidden;
}

@media only screen and (min-width: 768px) {
  .JobBlock_container__1nYQG {
    width: 30vw;
  }
}

.ProjectSelector_container__3HpiI {
  display: none;
  height: 100%;
  width: 30%;
  border-right: solid 1px var(--color-green);
}

.ProjectSelector_button__1yffS {
  transition: 0.5s ease;
  font-family: 'Roboto Mono', monospace;
  color: white;
  padding-right: 1em;
  padding-left: 1em;
}

.ProjectSelector_button__1yffS:hover {
  transition: 0.5s ease;
  text-decoration: underline;

  /* background-color: var(--main-white); */
  color: var(--color-green);
  opacity: 0.6;
  cursor: pointer;
}

.ProjectSelector_button__1yffS h4 {
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  margin-top: 0;
  margin-bottom: 0;
}

@media only screen and (min-width: 768px) {
  .ProjectSelector_container__3HpiI {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.ProjectModule_container__3Zy0c {
  font-family: 'Roboto Mono', monospace;
  margin-left: 0.5em;
}

.ProjectModule_container__3Zy0c h2 {
  margin-top: 0;
  margin-bottom: 0;
  padding-bottom: 0;
  color: var(--color-green);
}

.ProjectModule_textbox__2ORKI {
  color: var(--main-white);
  margin-top: 0;
}

.ProjectModule_content__21ISQ {
  width: 95vw;
  height: 60vh;
  overflow-y: scroll;
}

.ProjectModule_links__3nnFP {
  font-size: 30px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.ProjectModule_links__3nnFP a {
  margin-right: 1em;
  color: var(--main-white);
}

.ProjectModule_links__3nnFP a:hover {
  color: var(--color-green);
}

@media only screen and (min-width: 768px) {
  .ProjectModule_links__3nnFP {
    justify-content: flex-start;
  }

  .ProjectModule_content__21ISQ {
    height: 32vh;
    width: auto;
  }
}

.ProjectContainer_container__1wh3E {
  display: flex;
  flex-direction: row;
  width: 95vw;
}

.ProjectContainer_smallScroll__1P79U {
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
}

.ProjectContainer_hideSmall__1qoxZ {
  display: none;
}

.ProjectContainer_scrollModule__33uBO {
  scroll-snap-align: start;
  width: 100vw;
}

::-webkit-scrollbar {
  display: none;
}

.ProjectContainer_loadingContainer__SN851 {
  width: 100vw;
  height: 75vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (min-width: 768px) {
  .ProjectContainer_container__1wh3E {
    width: 40vw;
    height: 40vh;
  }

  .ProjectContainer_smallScroll__1P79U {
    display: none;
  }

  .ProjectContainer_hideSmall__1qoxZ {
    display: flex;
    flex-direction: column;
  }
}

.EmailForm_container__3l__W {
  display: flex;
  justify-content: center;
  color: var(--color-green);
  font-size: 1.5em;
  font-family: 'Roboto Monospace', monospace;
}

@media only screen and (min-width: 768px) {
  .EmailForm_container__3l__W {
    margin-left: 0;
    margin-right: 0;
    font-size: 2em;
  }
}

.ContactContainer_container__2snyJ {
  height: 100%;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.ContactContainer_social__2hYFi {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.ContactContainer_social__2hYFi h2 {
  text-align: center;
}

@supports (scroll-snap-align: start) {
  .ContactContainer_container__2snyJ {
    scroll-snap-align: start;
  }
}

@supports not (scroll-snap-align: start) {
  /* old scroll snap points spec */
  .ContactContainer_container__2snyJ {
    scroll-snap-coordinate: 0 0;
  }
}

.ContactContainer_iconsContainer__1oO56 {
  font-size: 3em;
  display: flex;
  justify-content: center;
}

.ContactContainer_iconsContainer__1oO56 a {
  color: var(--main-white);
  margin: 1em;
}

.ContactContainer_iconsContainer__1oO56 a:hover {
  color: var(--color-green);
}

@media only screen and (min-width: 768px) {
  .ContactContainer_container__2snyJ {
    margin-left: 0;
    margin-right: 0;
  }
}

.Footer_container__st7Zp {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  font-size: 2em;
  background-color: var(--main-white);
  font-family: 'Roboto Mono', monospace;
}

.Footer_container__st7Zp i {
  color: var(--color-green);
  margin-left: 0.2em;
  margin-right: 0.2em;
}

.Loading_foldingCube__9PLY8 {
  margin: 20px auto;
  width: 40px;
  height: 40px;
  position: relative;
  -webkit-transform: rotateZ(45deg);
  transform: rotateZ(45deg);
}

.Loading_foldingCube__9PLY8 .Loading_cube__3k5YW {
  float: left;
  width: 50%;
  height: 50%;
  position: relative;
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.Loading_foldingCube__9PLY8 .Loading_cube__3k5YW::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--color-green);
  -webkit-animation: Loading_sk-foldCubeAngle__1Gla9 2.4s infinite linear both;
  animation: Loading_sk-foldCubeAngle__1Gla9 2.4s infinite linear both;
  -webkit-transform-origin: 100% 100%;
  transform-origin: 100% 100%;
}

.Loading_foldingCube__9PLY8 .Loading_cube2__2ztHB {
  -webkit-transform: scale(1.1) rotateZ(90deg);
  transform: scale(1.1) rotateZ(90deg);
}

.Loading_foldingCube__9PLY8 .Loading_cube3__3RRh9 {
  -webkit-transform: scale(1.1) rotateZ(180deg);
  transform: scale(1.1) rotateZ(180deg);
}

.Loading_foldingCube__9PLY8 .Loading_cube4__tYjbL {
  -webkit-transform: scale(1.1) rotateZ(270deg);
  transform: scale(1.1) rotateZ(270deg);
}

.Loading_foldingCube__9PLY8 .Loading_cube2__2ztHB::before {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}

.Loading_foldingCube__9PLY8 .Loading_cube3__3RRh9::before {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

.Loading_foldingCube__9PLY8 .Loading_cube4__tYjbL::before {
  -webkit-animation-delay: 0.9s;
  animation-delay: 0.9s;
}

@-webkit-keyframes Loading_sk-foldCubeAngle__1Gla9 {
  0%,
  10% {
    -webkit-transform: perspective(140px) rotateX(-180deg);
    transform: perspective(140px) rotateX(-180deg);
    opacity: 0;
  }

  25%,
  75% {
    -webkit-transform: perspective(140px) rotateX(0deg);
    transform: perspective(140px) rotateX(0deg);
    opacity: 1;
  }

  90%,
  100% {
    -webkit-transform: perspective(140px) rotateY(180deg);
    transform: perspective(140px) rotateY(180deg);
    opacity: 0;
  }
}

@keyframes Loading_sk-foldCubeAngle__1Gla9 {
  0%,
  10% {
    -webkit-transform: perspective(140px) rotateX(-180deg);
    transform: perspective(140px) rotateX(-180deg);
    opacity: 0;
  }

  25%,
  75% {
    -webkit-transform: perspective(140px) rotateX(0deg);
    transform: perspective(140px) rotateX(0deg);
    opacity: 1;
  }

  90%,
  100% {
    -webkit-transform: perspective(140px) rotateY(180deg);
    transform: perspective(140px) rotateY(180deg);
    opacity: 0;
  }
}

.Home_container__3Z4dY {
  width: 100%;
  min-height: 100vh;
}

@supports (scroll-snap-align: start) {
  .Home_container__3Z4dY {
    scroll-snap-align: start;
  }
}

@supports not (scroll-snap-align: start) {
  /* old scroll snap points spec */
  .Home_container__3Z4dY {
    scroll-snap-coordinate: 0 0;
  }
}

.Home_bio_intro__23sNv {
  margin-bottom: 3em;
}

.Home_intro_name__2Z1pm {
  font-size: 2em;
  text-decoration: underline;
  font-style: italic;
}

.Home_slants__3cwO8 {
  position: -webkit-sticky;
  position: sticky;
}

.Home_slantLeft__3UXWp {
  position: absolute;
  width: 0;
  height: 0;
  z-index: -2;
  border-top: 38vh solid var(--color-blue);
  border-right: 50vw solid transparent;
}

.Home_slantRight__2T9IX {
  position: absolute;
  width: 0;
  height: 0;
  z-index: -2;
  right: 0;
  border-top: 25vh solid var(--color-green);
  border-left: 75vw solid transparent;
}

.Home_content__2CFUC {
  margin-top: 0;

  /* margin-left: 2em;
  margin-right: 1em;
  padding-top: 1em; */
  scroll-snap-align: start;
}

.Home_introContainer__CW_WQ {
  margin-left: 1em;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw - 1em;
}

.Home_introTitle__MVMSO h3 {
  font-family: 'Roboto Mono', monospace;
  color: var(--color-green);
}

.Home_introTitle__MVMSO h1 {
  color: var(--main-white);
}

.Home_introTitle__MVMSO h1:nth-child(3n) {
  opacity: 0.6;
}

@media only screen and (min-width: 768px) {
  .Home_introContainer__CW_WQ {
    margin-left: 0;
    width: 100vw;
  }
}

.About_container__h5LwU {
  min-height: 100vh;
  background-color: var(--main-bg-color);
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--main-white);
  margin-left: 1em;
  margin-right: 1em;
}

@supports (scroll-snap-align: start) {
  .About_container__h5LwU {
    scroll-snap-align: start;
  }
}

@supports not (scroll-snap-align: start) {
  /* old scroll snap points spec */
  .About_container__h5LwU {
    scroll-snap-coordinate: 0 0;
  }
}

.About_content__3clsp {
  justify-content: center;
  padding-left: 1em;
}

h2 {
  font-family: 'Roboto Mono', monospace;
  font-size: 2em;
}

.About_text__1r4zY {
  font-size: 1.5em;
  font-family: 'Roboto Mono', monospace;
}

@media only screen and (min-width: 768px) {
  .About_container__h5LwU {
    margin-left: 0;
    margin-right: 0;
  }

  .About_content__3clsp {
    justify-content: center;
    padding-left: 3em;
  }

  .About_text__1r4zY {
    width: 50%;
  }
}

.Experience_container__1E99F {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@supports (scroll-snap-align: start) {
  .Experience_container__1E99F {
    scroll-snap-align: start;
  }
}

@supports not (scroll-snap-align: start) {
  /* old scroll snap points spec */
  .Experience_container__1E99F {
    scroll-snap-coordinate: 0 0;
  }
}

h2 {
  color: var(--main-white);
}

.Projects_container__1tq7U {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Projects_projectsContainer__3N7Jj {
  min-height: 80vh;
  display: flex;
  flex-direction: column;
}

@media only screen and (min-width: 768px) {
  .Projects_projectsContainer__3N7Jj {
    justify-content: center;
  }
}

@supports (scroll-snap-align: start) {
  .Projects_container__1tq7U {
    scroll-snap-align: start;
  }
}

@supports not (scroll-snap-align: start) {
  /* old scroll snap points spec */
  .Projects_container__1tq7U {
    scroll-snap-coordinate: 0 0;
  }
}

.Contact_container__1IPGy {
  min-height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-left: 1em;
  margin-right: 1em;
}

@supports (scroll-snap-align: start) {
  .Contact_container__1IPGy {
    scroll-snap-align: start;
  }
}

@supports not (scroll-snap-align: start) {
  /* old scroll snap points spec */
  .Contact_container__1IPGy {
    scroll-snap-coordinate: 0 0;
  }
}

.Contact_content__8b7ft {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 80vh;
}

@media only screen and (min-width: 768px) {
  .Contact_container__1IPGy {
    margin-left: 0;
    margin-right: 0;
  }
}

