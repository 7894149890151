.container {
  min-height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-left: 1em;
  margin-right: 1em;
}

@supports (scroll-snap-align: start) {
  .container {
    scroll-snap-align: start;
  }
}

@supports not (scroll-snap-align: start) {
  /* old scroll snap points spec */
  .container {
    scroll-snap-coordinate: 0 0;
  }
}

.content {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 80vh;
}

@media only screen and (min-width: 768px) {
  .container {
    margin-left: 0;
    margin-right: 0;
  }
}
