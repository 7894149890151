.normal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 4px solid var(--dark-color);
  color: var(--dark-color);
  cursor: pointer;
  font-size: 1.7em;
  transition: 0.5s ease;

  /* padding: 0.25em 0.5em 0.25em 0.5em; */
}

.normal:hover {
  background-color: var(--dark-color);
  border-color: var(--dark-color);
  color: var(--main-bg-color);
  transition: 0.5s ease;
}
