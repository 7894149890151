.container {
  display: flex;
  flex-direction: row;
  width: 95vw;
}

.smallScroll {
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
}

.hideSmall {
  display: none;
}

.scrollModule {
  scroll-snap-align: start;
  width: 100vw;
}

::-webkit-scrollbar {
  display: none;
}

.loadingContainer {
  width: 100vw;
  height: 75vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (min-width: 768px) {
  .container {
    width: 40vw;
    height: 40vh;
  }

  .smallScroll {
    display: none;
  }

  .hideSmall {
    display: flex;
    flex-direction: column;
  }
}
