.container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.projectsContainer {
  min-height: 80vh;
  display: flex;
  flex-direction: column;
}

@media only screen and (min-width: 768px) {
  .projectsContainer {
    justify-content: center;
  }
}

@supports (scroll-snap-align: start) {
  .container {
    scroll-snap-align: start;
  }
}

@supports not (scroll-snap-align: start) {
  /* old scroll snap points spec */
  .container {
    scroll-snap-coordinate: 0 0;
  }
}
