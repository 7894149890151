.App {
  margin-left: -11px;
  margin-bottom: -11px;
  height: 100vh;
  overflow-y: scroll;
  scroll-behavior: smooth;

  /* scroll-snap-type: y mandatory; */
  -webkit-overflow-scrolling: touch;
}

@supports (scroll-snap-align: start) {
  /* modern scroll snap points */
  .App {
    scroll-snap-type: y mandatory;
  }
}

@supports not (scroll-snap-align: start) {
  /* old scroll snap points spec */
  .App {
    -webkit-scroll-snap-type: mandatory;
    scroll-snap-type: mandatory;
    -webkit-scroll-snap-destination: 100% 0;
    scroll-snap-destination: 100% 0;
    -webkit-scroll-snap-points-y: repeat(100%);
    scroll-snap-points-y: repeat(100%);
  }

  .scroll-item-outer {
    scroll-snap-coordinate: 0 0;
  }
}

::-webkit-scrollbar {
  width: 0;  /* remove scrollbar space
  background: transparent;  /* optional: just make scrollbar invisible */
}

/* optional: show position indicator in red */
::-webkit-scrollbar-thumb {
  background: #ecf0ff;
}
