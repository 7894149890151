.container {
  width: 95vw;
}

.container h3 {
  font-family: 'Roboto Mono', monospace;
  color: var(--color-green);
  margin-bottom: 5px;
}

.container h4 {
  color: var(--main-white);
  opacity: 0.6;
  margin-bottom: 0;
  margin-top: 0;
}

.container p {
  font-family: 'Roboto Mono', monospace;
  color: var(--main-white);
  margin-top: 0;
  overflow-x: hidden;
}

@media only screen and (min-width: 768px) {
  .container {
    width: 30vw;
  }
}
